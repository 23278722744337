
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import NewJobPhraseModal from "@/components/modals/forms/NewJobPhraseModal.vue";
import EditJobPhraseModal from "@/components/modals/forms/EditJobPhraseModal.vue";

export default defineComponent({
  name: "incline-job-phrases",
  //props: { behavioralwordid: Number },
  components: {
    TableSkeleton,
    NewJobPhraseModal,
    EditJobPhraseModal,
  },
  setup() {
    const store = useStore();
    const isDataReady = ref(false);
    const jobPhrases = computed(() => {
      return store.getters.getJobPhraseList;
    });
    const autonomyCount = computed(() => {
      return store.getters.getJOBAutonomyTraitCount;
    });
    const socialCount = computed(() => {
      return store.getters.getJOBSocialTraitCount;
    });
    const patienceCount = computed(() => {
      return store.getters.getJOBPatienceTraitCount;
    });
    const certaintyCount = computed(() => {
      return store.getters.getJOBCertaintyTraitCount;
    });
    const judgementCount = computed(() => {
      return store.getters.getJOBJudgementTraitCount;
    });
    const distractorCount = computed(() => {
      return store.getters.getJOBDistractorCount;
    });
    const activePhrases = computed(() => {
      return store.getters.getActiveJOBPhrases;
    });
    const searchWords = ref("");

    onMounted(() => {
      setCurrentPageTitle("JOB Phrases");
      setCurrentPageBreadcrumbs("JOB Phrases", ["Admin"]);
    });
    const phraseid = ref<number>();
    const editPhraseClick = (id: number) => {
      phraseid.value = id;
    };

    store
      .dispatch(Actions.GET_JOB_PHRASES)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving phrases.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    store
      .dispatch(Actions.GET_PRINCIPAL_TRAITS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving principal traits",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const activateDeactivatePhrase = (id) => {
      Swal.fire({
        title: "Are you sure you want to change the active status this phrase?",
        showCancelButton: true,
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DEACTIVATE_REACTIVATE_JOB_PHRASE, id)
            .then(() => {
              Swal.fire("The phrase has been updated!", "", "success");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem archiving your phrase.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const filterJobPhrases = computed(() => {
      return jobPhrases.value.filter((jobPhrase) => {
        return (
          jobPhrase.phrase
            .toLowerCase()
            .indexOf(searchWords.value.toLowerCase()) != -1 ||
          jobPhrase.trait
            .toLowerCase()
            .indexOf(searchWords.value.toLowerCase()) != -1
        );
      });
    });

    watch(jobPhrases, () => {
      isDataReady.value = true;
    });

    return {
      isDataReady,
      phraseid,
      editPhraseClick,
      searchWords,
      filterJobPhrases,
      jobPhrases,
      autonomyCount,
      socialCount,
      patienceCount,
      certaintyCount,
      judgementCount,
      distractorCount,
      activePhrases,
      activateDeactivatePhrase,
    };
  },
});
