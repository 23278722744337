
import { defineComponent, ref, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { JobPhrase } from "../../../core/types/JOBTypes";

export default defineComponent({
  name: "edit-job-phrase-modal",
  emit: ["modalClose"],
  props: { jobphraseid: Number },
  components: { Field, Form, ErrorMessage },
  setup(props) {
    const store = useStore();
    const phraseInfo = computed(() => {
      return store.getters.getJobPhraseById(Number(props.jobphraseid));
    });
    const submitButton = ref<HTMLElement | null>(null);
    const modalRef = ref<null | HTMLFormElement>(null);

    const wordValidation = Yup.object().shape({
      phrase: Yup.string().required().label("JOB Phrase"),
    });
    const jobPhrase = ref<JobPhrase>({
      jobPhraseId: phraseInfo.value.jobPhraseId,
      phrase: phraseInfo.value.phrase,
      active: phraseInfo.value.active,
      order: phraseInfo.value.order,
      principalTrait: phraseInfo.value.principalTrait,
    });
    const principalTraitList = computed(() => {
      return store.getters.getPrincipalTraitList;
    });

    watch(phraseInfo, () => {
      jobPhrase.value.phrase = phraseInfo.value.phrase;
      jobPhrase.value.active = phraseInfo.value.active;
      jobPhrase.value.order = phraseInfo.value.order;
      jobPhrase.value.principalTrait = phraseInfo.value.principalTrait;
    });

    const onSubmitPhrase = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      jobPhrase.value.jobPhraseId = Number(props.jobphraseid);
      // Send login request
      store
        .dispatch(Actions.PUT_JOB_PHRASE, jobPhrase.value)
        .then(() => {
          Swal.fire({
            text: "Success. The phrase has been updated.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
    }

    return {
      jobPhrase,
      principalTraitList,
      phraseInfo,
      closeModal,
      modalRef,
      wordValidation,
      onSubmitPhrase,
      submitButton,
    };
  },
});
