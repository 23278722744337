
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { JobPhrase } from "../../../core/types/JOBTypes";
import { PrincipalTrait } from "../../../core/types/TraitTypes";

export default defineComponent({
  name: "new-job-phrase-modal",
  props: {},
  components: { Field, Form, ErrorMessage },
  setup() {
    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);
    const modalRef = ref<null | HTMLFormElement>(null);

    const jobPhrase = ref<JobPhrase>({
      jobPhraseId: 0,
      phrase: "",
      active: true,
      order: 0,
      principalTrait: {} as PrincipalTrait,
    });

    const principalTraitList = computed(() => {
      return store.getters.getPrincipalTraitList;
    });

    const phraseValidation = Yup.object().shape({
      phrase: Yup.string().required().label("Phrase"),
    });

    const onSubmitClient = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.CREATE_JOB_PHRASE, jobPhrase.value)
        .then(() => {
          Swal.fire({
            text: "Success. The new phrase has been added.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            resetForm();
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      jobPhrase.value = {
        jobPhraseId: 0,
        phrase: "",
        active: true,
        order: 0,
        principalTrait: {} as PrincipalTrait,
      };
    }

    return {
      jobPhrase,
      principalTraitList,
      closeModal,
      resetForm,
      modalRef,
      phraseValidation,
      onSubmitClient,
      submitButton,
    };
  },
});
