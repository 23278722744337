<template>
  <!--begin::Modal - New Target-->
  <div
    class="modal fade"
    id="modal_edit_job_phrase"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            ref="modalRef"
            @click="closeModal()"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            novalidate="novalidate"
            @submit="onSubmitPhrase"
            id="edit_phrase_form"
            :validation-schema="wordValidation"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">Edit JOB Phrase</h1>
              <!--end::Title-->
            </div>
            <!--end::Heading-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">JOB Phrase</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="phrase"
                name="phrase"
                autocomplete="off"
                v-model="jobPhrase.phrase"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="phrase" />
                </div>
              </div>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span>Order</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="number"
                placeholder="order"
                name="order"
                autocomplete="off"
                v-model="jobPhrase.order"
              />
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2"
                  >Principal Trait</label
                >
                <div
                  class="mb-2"
                  v-for="item in principalTraitList"
                  :key="item.principalTraitId"
                >
                  <div class="form-check form-check-custom form-check-solid">
                    <input
                      class="form-check-input"
                      type="radio"
                      :value="item"
                      v-model="jobPhrase.principalTrait"
                      :id="'flexCheckDefault' + item.principalTraitId"
                    />
                    <label
                      class="form-check-label"
                      :for="'flexCheckDefault' + item.principalTraitId"
                      >{{ item.trait }}</label
                    >
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Actions-->
            <div class="text-center">
              <button
                type="reset"
                id="incline_modal_edit_phrase_cancel"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button
                id="incline_edit_phrase_submit"
                ref="submitButton"
                type="submit"
                class="btn btn-lg btn-primary"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { JobPhrase } from "../../../core/types/JOBTypes";

export default defineComponent({
  name: "edit-job-phrase-modal",
  emit: ["modalClose"],
  props: { jobphraseid: Number },
  components: { Field, Form, ErrorMessage },
  setup(props) {
    const store = useStore();
    const phraseInfo = computed(() => {
      return store.getters.getJobPhraseById(Number(props.jobphraseid));
    });
    const submitButton = ref<HTMLElement | null>(null);
    const modalRef = ref<null | HTMLFormElement>(null);

    const wordValidation = Yup.object().shape({
      phrase: Yup.string().required().label("JOB Phrase"),
    });
    const jobPhrase = ref<JobPhrase>({
      jobPhraseId: phraseInfo.value.jobPhraseId,
      phrase: phraseInfo.value.phrase,
      active: phraseInfo.value.active,
      order: phraseInfo.value.order,
      principalTrait: phraseInfo.value.principalTrait,
    });
    const principalTraitList = computed(() => {
      return store.getters.getPrincipalTraitList;
    });

    watch(phraseInfo, () => {
      jobPhrase.value.phrase = phraseInfo.value.phrase;
      jobPhrase.value.active = phraseInfo.value.active;
      jobPhrase.value.order = phraseInfo.value.order;
      jobPhrase.value.principalTrait = phraseInfo.value.principalTrait;
    });

    const onSubmitPhrase = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      jobPhrase.value.jobPhraseId = Number(props.jobphraseid);
      // Send login request
      store
        .dispatch(Actions.PUT_JOB_PHRASE, jobPhrase.value)
        .then(() => {
          Swal.fire({
            text: "Success. The phrase has been updated.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
    }

    return {
      jobPhrase,
      principalTraitList,
      phraseInfo,
      closeModal,
      modalRef,
      wordValidation,
      onSubmitPhrase,
      submitButton,
    };
  },
});
</script>
