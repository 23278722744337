<template>
  <!--begin::Modal - New Target-->
  <div
    class="modal fade"
    id="modal_new_job-phrase"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            ref="modalRef"
            @click="closeModal()"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            novalidate="novalidate"
            @submit="onSubmitClient"
            id="new_client_form"
            :validation-schema="phraseValidation"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">Create New Phrase</h1>
              <!--end::Title-->
            </div>
            <!--end::Heading-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Phrase</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="phrase"
                name="phrase"
                autocomplete="off"
                v-model="jobPhrase.phrase"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="phrase" />
                </div>
              </div>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span>Order</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-lg form-control-solid"
                type="number"
                placeholder="order"
                name="order"
                autocomplete="off"
                v-model="jobPhrase.order"
              />
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2"
                  >Principal Trait(s)</label
                >
                <div
                  class="mb-2"
                  v-for="item in principalTraitList"
                  :key="item.principalTraitId"
                >
                  <div class="form-check form-check-custom form-check-solid">
                    <input
                      class="form-check-input"
                      type="radio"
                      :value="item"
                      v-model="jobPhrase.principalTrait"
                      :id="'flexCheckDefault' + item.principalTraitId"
                      name="trait"
                    />
                    <label
                      class="form-check-label"
                      :for="'flexCheckDefault' + item.principalTraitId"
                      >{{ item.trait }}</label
                    >
                  </div>
                </div>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="trait" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Actions-->
            <div class="text-center">
              <button
                type="reset"
                id="incline_modal_new_location_cancel"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
                @click="resetForm()"
              >
                Cancel
              </button>

              <button
                id="incline_new_word_submit"
                ref="submitButton"
                type="submit"
                class="btn btn-lg btn-primary"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { JobPhrase } from "../../../core/types/JOBTypes";
import { PrincipalTrait } from "../../../core/types/TraitTypes";

export default defineComponent({
  name: "new-job-phrase-modal",
  props: {},
  components: { Field, Form, ErrorMessage },
  setup() {
    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);
    const modalRef = ref<null | HTMLFormElement>(null);

    const jobPhrase = ref<JobPhrase>({
      jobPhraseId: 0,
      phrase: "",
      active: true,
      order: 0,
      principalTrait: {} as PrincipalTrait,
    });

    const principalTraitList = computed(() => {
      return store.getters.getPrincipalTraitList;
    });

    const phraseValidation = Yup.object().shape({
      phrase: Yup.string().required().label("Phrase"),
    });

    const onSubmitClient = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.CREATE_JOB_PHRASE, jobPhrase.value)
        .then(() => {
          Swal.fire({
            text: "Success. The new phrase has been added.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            resetForm();
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      jobPhrase.value = {
        jobPhraseId: 0,
        phrase: "",
        active: true,
        order: 0,
        principalTrait: {} as PrincipalTrait,
      };
    }

    return {
      jobPhrase,
      principalTraitList,
      closeModal,
      resetForm,
      modalRef,
      phraseValidation,
      onSubmitClient,
      submitButton,
    };
  },
});
</script>
